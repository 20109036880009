import { Navigate, Outlet } from "react-router-dom";

const loginCheck = () => {
  let user;
  try {
    user = JSON.parse(localStorage.getItem("encryptedToken"));
  } catch (error) {
    console.error("Error parsing user data:", error);
    // Handle the error appropriately, e.g., clear localStorage or redirect to login
    localStorage.removeItem("encryptedToken");
    return <Navigate to="/login" />;
  }

  // if (!user) {
  //   return <Navigate to="/login" />;
  // }

  return user?.role === "admin" ? (
    <Navigate to="/admin" />
  ) : user?.role === "vendor" ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  );
};

export default loginCheck;
