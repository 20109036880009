import React, { useEffect, useState } from "react";
import OverViewCards from "./OverViewCards";

import { flickOverView } from "../../../../Api/ApiCall";
import { useParams } from "react-router-dom";

function OverView() {
  const [flick, setflick] = useState();
  const [totalViewed, setTotalViewed] = useState(0);
  const [totalsaved, setTotalsaved] = useState(0);
  const [CountryData, setCountryData] = useState();
  const [countryDataUnder, setCountryDataUnder] = useState([]);
  const { flickId } = useParams();
  useEffect(() => {
    console.log(flickId, "flickId");
    getFlickDetails(flickId);
  }, [flickId]);
  const getFlickDetails = (flickId) => {
    flickOverView(flickId)
      .then((data) => {
        console.log("dataasa", data?.data?.mostViewedCountries);
        setCountryDataUnder(data?.data?.mostViewedCountries);
        setflick(data?.data?.flick);
        console.log(data?.data?.flicks);
        setTotalsaved(data?.data?.totalSaved);
        setTotalViewed(data?.data?.totalVisitors);
        setCountryData(data?.data?.countriesWithCount);
        console.log(
          data?.data?.countriesWithCount,
          "country data %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="p-4">
      <div className="bg-containerWhite flex justify-between w-full h-16 shadow-md rounded-lg ">
        <div className="flex max-h-16 items-center rounded-lg overflow-hidden gap-2">
          <img src={flick?.banner} className="w-16 h-full rounded-lg" alt="" />
          <div className="p-2 text-xs">
            <p>{flick?.name}</p>
            <p>{flick?.releasedYear}</p>
          </div>
        </div>
        <div className="flex items-center gap-3 p-2">
          {flick?.rating != 0 ? (
            <div>
              <p>{flick?.rating}</p>
              <img src="/star.png" className="w-4" alt="" />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* cardsection */}
      <section>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-5 gap-5">
          <OverViewCards
            data={totalViewed}
            img={"/eye.png"}
            text={"Total Visitors"}
          />
          {/* <OverViewCards
            data={data2}
            img={"/flover.png"}
            text={"Total Income"}
          /> */}
          <OverViewCards
            data={totalsaved}
            img={"/imagee.png"}
            text={"Total  Saved"}
          />
          {/* <OverViewCards
            data={data1}
            img={"/download.png"}
            text={"Total Shared"}
          /> */}
        </div>
      </section>
      {/* cardsection */}
      {/* graphsection */}

    </div>
  );
}

export default OverView;
