export const toggleProductHeight = (productsExpand, setProductsExpand) => {
  setProductsExpand(!productsExpand);
  const div = document.getElementById("products-div");
  const maxHeight = productsExpand ? "50px" : "0px";
  div.style.height = maxHeight;
};


//NO Heights......>>>>>>>>>>>>>>>>>>>>

export const toggleProductNoHeight = (productsExpand, setProductsExpand) => {
  const div = document.getElementById("products-div");
  const maxHeight = "0px";
  div.style.height = maxHeight;
};

