

export const localDateConverter = (utcDateString) => {
  if(!utcDateString) return
const date = new Date(utcDateString);
const localDateString = date
  .toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
  .split("/")
  .join("-");




  return localDateString
};
export const formatStorage = (sizeInMB) => {
  if (sizeInMB >= 1024) {
    return (sizeInMB / 1024).toFixed(2) + " GB";
  } else {
    return sizeInMB.toFixed(2) + " MB";
  }
}
export const convertToLocalDateTime=(utcDateString)=> {
  const date = new Date(utcDateString);
  
  const localDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).split('/').join('-');

  const localTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  return {
    date: localDate,
    time: localTime
  };
}



