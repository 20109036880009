import React from "react";

function BAnnerSection({ data, loading }) {
  return (
    <div className="mt-5">
      <table className="min-w-full  leading-normal">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider ">
              <div className="flex gap-1 items-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.4896 3.97289C15.3264 3.58723 15.1248 3.21544 14.89 2.86765C14.6575 2.52359 14.3902 2.19981 14.0963 1.90536C13.8019 1.61092 13.4781 1.34421 13.134 1.11164C12.7863 0.876937 12.4145 0.674772 12.0288 0.511547C11.2265 0.172294 10.3747 0 9.49667 0C8.61867 0 7.7668 0.172294 6.96454 0.511547C6.57888 0.674772 6.20709 0.876404 5.8593 1.11164C5.51525 1.34421 5.19146 1.61145 4.89702 1.90536C4.60257 2.19981 4.33586 2.52359 4.10329 2.86765C3.86859 3.21544 3.66643 3.58723 3.50373 3.97289C3.16448 4.77515 2.99219 5.62701 2.99219 6.50448C2.99219 7.38249 3.16448 8.23435 3.50373 9.03661C3.66696 9.42227 3.86859 9.79407 4.10329 10.1419C4.33586 10.4864 4.60311 10.8097 4.89702 11.1041C5.19146 11.3986 5.51525 11.6653 5.8593 11.8984C6.20709 12.1331 6.57888 12.3353 6.96454 12.4985C7.7668 12.8377 8.61867 13.01 9.49667 13.01C10.3747 13.01 11.2265 12.8377 12.0288 12.4985C12.4145 12.3353 12.7863 12.1336 13.134 11.8984C13.4781 11.6658 13.8019 11.3986 14.0963 11.1041C14.3908 10.8097 14.6575 10.4859 14.89 10.1419C15.1248 9.79407 15.3269 9.42227 15.4896 9.03661C15.8289 8.23435 16.0012 7.38249 16.0012 6.50448C16.0012 5.62701 15.8289 4.77515 15.4896 3.97289ZM9.49614 11.9437C6.49674 11.9437 4.05689 9.50389 4.05689 6.50448C4.05689 3.50562 6.49674 1.06523 9.49614 1.06523C12.4955 1.06523 14.9354 3.50508 14.9354 6.50448C14.9354 9.50389 12.4955 11.9437 9.49614 11.9437Z"
                    fill="#2F4EFF"
                  />
                  <path
                    d="M3.24024 12.0076L0.156024 15.0918C-0.0520081 15.2998 -0.0520081 15.637 0.156024 15.845C0.260041 15.949 0.396595 16.0007 0.532616 16.0007C0.668638 16.0007 0.805192 15.949 0.909209 15.845L3.99342 12.7608C4.20146 12.5527 4.20146 12.2156 3.99342 12.0076C3.78593 11.7996 3.44827 11.7996 3.24024 12.0076Z"
                    fill="#2F4EFF"
                  />
                  <path
                    d="M6.4782 3.66797C6.18375 3.66797 5.94531 3.90641 5.94531 4.20085V8.86718C5.94531 9.16163 6.18375 9.40007 6.4782 9.40007C6.77264 9.40007 7.01108 9.16163 7.01108 8.86718V4.20085C7.01108 3.90641 6.77211 3.66797 6.4782 3.66797Z"
                    fill="#2F4EFF"
                  />
                  <path
                    d="M8.46648 3.66992C8.17203 3.66992 7.93359 3.90836 7.93359 4.20281V8.86914C7.93359 9.16358 8.17203 9.40202 8.46648 9.40202C8.76092 9.40202 8.99936 9.16358 8.99936 8.86914V4.20281C8.99883 3.90836 8.76039 3.66992 8.46648 3.66992Z"
                    fill="#2F4EFF"
                  />
                  <path
                    d="M12.4509 3.66797C12.1564 3.66797 11.918 3.90641 11.918 4.20085V8.86718C11.918 9.16163 12.1564 9.40007 12.4509 9.40007C12.7453 9.40007 12.9837 9.16163 12.9837 8.86718V4.20085C12.9837 3.90641 12.7453 3.66797 12.4509 3.66797Z"
                    fill="#2F4EFF"
                  />
                  <path
                    d="M10.4587 3.66797C10.1642 3.66797 9.92578 3.90641 9.92578 4.20085V8.86718C9.92578 9.16163 10.1642 9.40007 10.4587 9.40007C10.7531 9.40007 10.9915 9.16163 10.9915 8.86718V4.20085C10.9915 3.90641 10.7531 3.66797 10.4587 3.66797Z"
                    fill="#2F4EFF"
                  />
                </svg>
                vender name
              </div>
            </th>
            <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">
              <div className="flex gap-1 items-center">
                <img src="/cloud1.png" className="w-5 h-4" alt="" />
                banner id
              </div>
            </th>
            <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">
              <div className="flex gap-1 items-center">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.4971 1.23333V14.7667H5.63047V1.23333H14.4971ZM19.1638 3.1V12.9H15.8971V3.1H19.1638ZM4.23047 3.1V12.9H0.963802V3.1H4.23047ZM14.7305 1H5.39713V15H14.7305V1ZM19.3971 2.86667H15.6638V13.1333H19.3971V2.86667ZM4.4638 2.86667H0.730469V13.1333H4.4638V2.86667Z"
                    fill="black"
                    stroke="#212E47"
                    stroke-width="0.6"
                  />
                </svg>
                position
              </div>
            </th>
            <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">
              <div className="flex gap-1 items-center">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.96094 7.81818H0.654119C0.517756 7.81818 0.398438 7.69886 0.398438 7.5625V2.99432C0.398438 1.88636 1.2848 1 2.39276 1H6.96094C7.0973 1 7.21662 1.11932 7.21662 1.25568V7.5625C7.21662 7.69886 7.0973 7.81818 6.96094 7.81818ZM0.909801 7.30682H6.70526V1.51136H2.39276C1.57457 1.51136 0.909801 2.17614 0.909801 2.99432V7.30682Z"
                    fill="#9747FF"
                    stroke="#9747FF"
                    stroke-width="0.6"
                  />
                  <path
                    d="M6.96094 15.9998H2.39276C1.2848 15.9998 0.398438 15.1135 0.398438 14.0055V9.43732C0.398438 9.30096 0.517756 9.18164 0.654119 9.18164H6.96094C7.0973 9.18164 7.21662 9.30096 7.21662 9.43732V15.7441C7.21662 15.8805 7.0973 15.9998 6.96094 15.9998ZM0.909801 9.693V14.0055C0.909801 14.8237 1.57457 15.4885 2.39276 15.4885H6.70526V9.693H0.909801Z"
                    fill="#9747FF"
                    stroke="#9747FF"
                    stroke-width="0.6"
                  />
                  <path
                    d="M15.1406 7.81818H8.83381C8.69744 7.81818 8.57812 7.69886 8.57812 7.5625V1.25568C8.57812 1.11932 8.69744 1 8.83381 1H13.402C14.5099 1 15.3963 1.88636 15.3963 2.99432V7.5625C15.3963 7.69886 15.277 7.81818 15.1406 7.81818ZM9.08949 7.30682H14.8849V2.99432C14.8849 2.17614 14.2202 1.51136 13.402 1.51136H9.08949V7.30682Z"
                    fill="#9747FF"
                    stroke="#9747FF"
                    stroke-width="0.6"
                  />
                  <path
                    d="M13.402 15.9998H8.83381C8.69744 15.9998 8.57812 15.8805 8.57812 15.7441V9.43732C8.57812 9.30096 8.69744 9.18164 8.83381 9.18164H15.1406C15.277 9.18164 15.3963 9.30096 15.3963 9.43732V14.0055C15.3963 15.1135 14.5099 15.9998 13.402 15.9998ZM9.08949 15.4885H13.402C14.2202 15.4885 14.8849 14.8237 14.8849 14.0055V9.693H9.08949V15.4885Z"
                    fill="#9747FF"
                    stroke="#9747FF"
                    stroke-width="0.6"
                  />
                </svg>
                Category
              </div>
            </th>
            <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider ">
              <div className="flex gap-1 items-center">
                <svg
                  width="21"
                  height="18"
                  viewBox="0 0 21 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.4">
                    <path
                      d="M16.6154 1.03846C16.6154 0.463846 16.1515 0 15.5769 0H10.7308H6.57692H1.03846C0.463846 0 0 0.463846 0 1.03846V16.2692C0 16.8438 0.463846 17.3077 1.03846 17.3077H11.1392C11.2846 17.7092 11.6654 18 12.1154 18H19.0385C19.6131 18 20.0769 17.5362 20.0769 16.9615V10.0385C20.0769 9.46385 19.6131 9 19.0385 9H16.6154V1.03846ZM6.92308 0.692308H10.3846V2.07692H6.92308V0.692308ZM6.92308 2.76923H10.3846V5.04692L10.2808 4.94308C10.1423 4.80462 9.92769 4.80462 9.78923 4.94308L9.34615 5.39308L8.89615 4.94308C8.83385 4.88077 8.74385 4.84615 8.65385 4.84615C8.56385 4.84615 8.47385 4.88077 8.41154 4.95L7.96154 5.39308L7.51154 4.94308C7.37308 4.80462 7.15846 4.80462 7.02 4.94308L6.91615 5.04692V2.76923H6.92308ZM11.0769 10.0385V16.6154H1.03846C0.844615 16.6154 0.692308 16.4631 0.692308 16.2692V1.03846C0.692308 0.844615 0.844615 0.692308 1.03846 0.692308H6.23077V5.88462C6.23077 6.02308 6.31385 6.14769 6.44538 6.20308C6.57692 6.25846 6.72231 6.22385 6.81923 6.12692L7.26923 5.68385L7.71923 6.13385C7.85769 6.27231 8.07231 6.27231 8.21077 6.13385L8.65385 5.68385L9.10385 6.13385C9.24231 6.27231 9.45692 6.27231 9.59539 6.13385L10.0385 5.68385L10.4885 6.13385C10.5508 6.19615 10.6408 6.23077 10.7308 6.23077C10.7723 6.23077 10.8208 6.22385 10.8623 6.20308C10.9938 6.14769 11.0769 6.02308 11.0769 5.88462V0.692308H15.5769C15.7708 0.692308 15.9231 0.844615 15.9231 1.03846V9H12.1154C11.5408 9 11.0769 9.46385 11.0769 10.0385ZM19.0385 9.69231C19.2323 9.69231 19.3846 9.84462 19.3846 10.0385V16.9615C19.3846 17.1554 19.2323 17.3077 19.0385 17.3077H12.1154C11.9215 17.3077 11.7692 17.1554 11.7692 16.9615V10.0385C11.7692 9.84462 11.9215 9.69231 12.1154 9.69231H19.0385Z"
                      fill="#FF9900"
                    />
                    <path
                      d="M14.1938 15.2309C14 15.2309 13.8477 15.3832 13.8477 15.5771C13.8477 15.7709 14 15.9232 14.1938 15.9232H15.2323V16.2694C15.2323 16.4632 15.3846 16.6155 15.5784 16.6155C15.7723 16.6155 15.9246 16.4632 15.9246 16.2694V15.9232C16.6861 15.9232 17.3092 15.3002 17.3092 14.5386C17.3092 13.7771 16.6861 13.154 15.9246 13.154V11.7694H16.963C17.1569 11.7694 17.3092 11.6171 17.3092 11.4232C17.3092 11.2294 17.1569 11.0771 16.963 11.0771H15.9246V10.7309C15.9246 10.5371 15.7723 10.3848 15.5784 10.3848C15.3846 10.3848 15.2323 10.5371 15.2323 10.7309V11.0771C14.4707 11.0771 13.8477 11.7002 13.8477 12.4617C13.8477 13.2232 14.4707 13.8463 15.2323 13.8463V15.2309H14.1938ZM15.9246 13.8463C16.3053 13.8463 16.6169 14.1578 16.6169 14.5386C16.6169 14.9194 16.3053 15.2309 15.9246 15.2309V13.8463ZM14.54 12.4617C14.54 12.0809 14.8515 11.7694 15.2323 11.7694V13.154C14.8515 13.154 14.54 12.8425 14.54 12.4617Z"
                      fill="#FF9900"
                    />
                  </g>
                </svg>
                price
              </div>
            </th>
            <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider ">
              <div className="flex gap-1 items-center">
                <svg
                  width="21"
                  height="18"
                  viewBox="0 0 21 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.4">
                    <path
                      d="M17.5294 1.03846C17.5294 0.463846 17.0656 0 16.491 0H11.6448H7.49099H1.95252C1.37791 0 0.914062 0.463846 0.914062 1.03846V16.2692C0.914062 16.8438 1.37791 17.3077 1.95252 17.3077H12.0533C12.1987 17.7092 12.5794 18 13.0294 18H19.9525C20.5271 18 20.991 17.5362 20.991 16.9615V10.0385C20.991 9.46385 20.5271 9 19.9525 9H17.5294V1.03846ZM7.83714 0.692308H11.2987V2.07692H7.83714V0.692308ZM7.83714 2.76923H11.2987V5.04692L11.1948 4.94308C11.0564 4.80462 10.8418 4.80462 10.7033 4.94308L10.2602 5.39308L9.81022 4.94308C9.74791 4.88077 9.65791 4.84615 9.56791 4.84615C9.47791 4.84615 9.38791 4.88077 9.3256 4.95L8.8756 5.39308L8.4256 4.94308C8.28714 4.80462 8.07252 4.80462 7.93406 4.94308L7.83022 5.04692V2.76923H7.83714ZM11.991 10.0385V16.6154H1.95252C1.75868 16.6154 1.60637 16.4631 1.60637 16.2692V1.03846C1.60637 0.844615 1.75868 0.692308 1.95252 0.692308H7.14483V5.88462C7.14483 6.02308 7.22791 6.14769 7.35945 6.20308C7.49099 6.25846 7.63637 6.22385 7.73329 6.12692L8.18329 5.68385L8.63329 6.13385C8.77175 6.27231 8.98637 6.27231 9.12483 6.13385L9.56791 5.68385L10.0179 6.13385C10.1564 6.27231 10.371 6.27231 10.5094 6.13385L10.9525 5.68385L11.4025 6.13385C11.4648 6.19615 11.5548 6.23077 11.6448 6.23077C11.6864 6.23077 11.7348 6.22385 11.7764 6.20308C11.9079 6.14769 11.991 6.02308 11.991 5.88462V0.692308H16.491C16.6848 0.692308 16.8371 0.844615 16.8371 1.03846V9H13.0294C12.4548 9 11.991 9.46385 11.991 10.0385ZM19.9525 9.69231C20.1464 9.69231 20.2987 9.84462 20.2987 10.0385V16.9615C20.2987 17.1554 20.1464 17.3077 19.9525 17.3077H13.0294C12.8356 17.3077 12.6833 17.1554 12.6833 16.9615V10.0385C12.6833 9.84462 12.8356 9.69231 13.0294 9.69231H19.9525Z"
                      fill="#FF9900"
                    />
                    <path
                      d="M15.1079 15.2309C14.914 15.2309 14.7617 15.3832 14.7617 15.5771C14.7617 15.7709 14.914 15.9232 15.1079 15.9232H16.1463V16.2694C16.1463 16.4632 16.2986 16.6155 16.4925 16.6155C16.6863 16.6155 16.8386 16.4632 16.8386 16.2694V15.9232C17.6002 15.9232 18.2233 15.3002 18.2233 14.5386C18.2233 13.7771 17.6002 13.154 16.8386 13.154V11.7694H17.8771C18.0709 11.7694 18.2233 11.6171 18.2233 11.4232C18.2233 11.2294 18.0709 11.0771 17.8771 11.0771H16.8386V10.7309C16.8386 10.5371 16.6863 10.3848 16.4925 10.3848C16.2986 10.3848 16.1463 10.5371 16.1463 10.7309V11.0771C15.3848 11.0771 14.7617 11.7002 14.7617 12.4617C14.7617 13.2232 15.3848 13.8463 16.1463 13.8463V15.2309H15.1079ZM16.8386 13.8463C17.2194 13.8463 17.5309 14.1578 17.5309 14.5386C17.5309 14.9194 17.2194 15.2309 16.8386 15.2309V13.8463ZM15.454 12.4617C15.454 12.0809 15.7656 11.7694 16.1463 11.7694V13.154C15.7656 13.154 15.454 12.8425 15.454 12.4617Z"
                      fill="#FF9900"
                    />
                  </g>
                </svg>
                payment status
              </div>
            </th>

            <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider ">
              <div className="flex gap-1 items-center">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.4">
                    <path
                      d="M0.521016 16.9219H2.78713C3.07905 16.9219 3.31596 16.6855 3.31596 16.393V12.5249C3.31596 12.2325 3.07905 11.9961 2.78713 11.9961H0.521016C0.229103 11.9961 -0.0078125 12.2325 -0.0078125 12.5249V16.393C-0.0078125 16.685 0.229103 16.9219 0.521016 16.9219ZM1.04984 13.0538H2.2583V15.8642H1.04984V13.0538Z"
                      fill="#DB00FF"
                    />
                    <path
                      d="M13.5898 0.528829V16.3937C13.5898 16.6861 13.8268 16.9225 14.1187 16.9225H16.3848C16.6767 16.9225 16.9136 16.6861 16.9136 16.3937V0.528829C16.9136 0.236386 16.6767 0 16.3848 0H14.1187C13.8268 0 13.5898 0.236386 13.5898 0.528829ZM14.6475 1.05766H15.8559V15.8649H14.6475V1.05766Z"
                      fill="#DB00FF"
                    />
                    <path
                      d="M7.31837 8.53906H5.05229C4.76035 8.53906 4.52344 8.77545 4.52344 9.06789V16.4715C4.52344 16.7639 4.76035 17.0003 5.05229 17.0003H7.31837C7.61029 17.0003 7.8472 16.7639 7.8472 16.4715V9.06789C7.8472 8.77545 7.61029 8.53906 7.31837 8.53906ZM6.78954 15.9427H5.58112V9.59672H6.78954V15.9427Z"
                      fill="#DB00FF"
                    />
                    <path
                      d="M9.05859 5.55617V16.4705C9.05859 16.763 9.29551 16.9993 9.58742 16.9993H11.8535C12.1454 16.9993 12.3823 16.763 12.3823 16.4705V5.55617C12.3823 5.26373 12.1454 5.02734 11.8535 5.02734H9.58742C9.29551 5.02734 9.05859 5.26373 9.05859 5.55617ZM10.1163 6.085H11.3247V15.9417H10.1163V6.085Z"
                      fill="#DB00FF"
                    />
                  </g>
                </svg>
                status
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="capitalize">
          {data?.length > 0 &&
            data.map((data) => {
              console.log(data);
              return (
                <tr className="text-center">
                  <td className="px-3 py-4">
                    <div className="flex gap-1 items-center">
                      <img
                        src={
                          data?.vendor?.profilePicture
                            ? data?.vendor?.profilePicture
                            : "/propic.png"
                        }
                        className="w-10 h-10 rounded-full"
                        alt=""
                      />
                      {data?.vendor?.name}
                    </div>
                  </td>
                  <td className="px-3 py-5">{data?.requestId}</td>
                  <td className="px-3 py-5 capitalize">{data?.position}</td>
                  <td className="px-3 py-5 capitalize">{data?.category}</td>
                  <td className="px-3 py-5 capitalize">{data?.amount?.toFixed()}</td>
                  <td
                    className={`px-3 py-5 capitalize ${
                      data?.paymentStatus === "Success"
                        ? "text-green-500"
                        : data?.paymentStatus === "Pending"
                        ? "text-[#FFB53A]"
                        : ""
                    } `}
                  >
                    {data?.paymentStatus}
                  </td>
                  <td
                    className={`px-3 py-5 capitalize ${
                      data?.status === "Publish"
                        ? "text-green-500"
                        : "text-[#FFB53A]"
                    } `}
                  >
                    {data?.status}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {/* {loading && (
        <div className="w-full p-4 flex items-center justify-center">
          <img src="/loading.gif" className="w-32" alt="" />
        </div>
      )} */}
      {data.length < 1 && !loading && (
        <div className="text-red-500 text-center p-4">Oops No Data</div>
      )}
    </div>
  );
}

export default BAnnerSection;
