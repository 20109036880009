import axios from "axios";
// import 
const baseURL = import.meta.env.VITE_REACT_APP_BASE_URL;
// async function getCountry() {
//   try {
//     const response = await axios.get("https://ipapi.co/json/");
//     console.log("cunrtre",response.data.country_name);
//     return response.data.country_name;
//   } catch (error) {
//     console.error("Error fetching country: ", error);
//     return "United States"; // Default to US or handle error appropriately
//   }
// }

const BulkInstance = axios.create({ baseURL });
BulkInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token"); //import.meta.env.VITE_REACT_APP_TOKEN;
    const appid = import.meta.env.VITE_REACT_APP_APPID;
    const country = localStorage.getItem("location");
    if (!country) {
       localStorage.removeItem("token");
       localStorage.removeItem("location");
       localStorage.removeItem("encryptedToken");
       localStorage.removeItem("Userdetails");
       window.location.href = "/login";
   }
     if (token) {
       config.headers["Authorization"] = "Bearer " + token;
     }
    if (appid) {
      config.headers["appid"] = appid;
    }
    config.headers["Country"] = country;
    // Only set Content-Type to multipart/form-data if you're sending form data with files
    // config.headers["Content-Type"] = "multipart/form-data";

    return config;
  },
  (error) => {
    console.error(
      "Request error:",
      error.response
    );
    return Promise.reject(error);
  }
);
BulkInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response &&
      error?.response.status === 401 &&
      error?.response.data.message === "User not found"
    ) {
      console.error("403 Forbidden error:", error.response.data);
      localStorage.removeItem("token");
      localStorage.removeItem("location");
      localStorage.removeItem("encryptedToken");
      localStorage.removeItem("Userdetails");
      window.location.href = "/login";
    }
     if (
       error?.response &&
       error?.response.status === 401 &&
       error?.response.data.message ===
         "Account Blocked: Your account has been blocked by the admin."
     ) {
       console.error("403 Forbidden error:", error.response.data);
       localStorage.removeItem("token");
       localStorage.removeItem("location");
       localStorage.removeItem("encryptedToken");
       localStorage.removeItem("Userdetails");
       window.location.href = "/blocked";
     }
     if (
       error?.response &&
       error?.response.status === 403 &&
       error?.response.data.message === "Invalid token"
     ) {
       console.error("403 Forbidden error:", error.response.data);
       localStorage.removeItem("token");
       localStorage.removeItem("location");
       localStorage.removeItem("encryptedToken");
       localStorage.removeItem("Userdetails");
       window.location.href = "/login";
     }
    // if (error.response && error.response.status === 403) {
    //   console.error("403 Forbidden error:", error.response.data);
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("location");
    //   localStorage.removeItem("encryptedToken");
    //   localStorage.removeItem("Userdetails");
    //   window.location.href = "/login";
    // }
    return Promise.reject(error);
  }
  
);
export default BulkInstance;
