
import axios from "axios";
import { toast } from "react-toastify";

const cloudName = "fouvtycloud";
const uploadPreset = "qw7jz8y5";

export const uploadImage = async (file) => {
  const maxSizeInBytes = 10 * 1024 * 1024;
  if (file?.size > maxSizeInBytes) {
    toast.info("File size exceeds 10 MB. Please upload a smaller file.");
    return;
  }

  const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
  const formData = new FormData();
  const preset = `${uploadPreset}`;

  formData.append("file", file?.image);
  formData.append("upload_preset", preset);
  console.log(file);
  const resourceType = file?.image?.type.startsWith("image/") ? "image" : "raw";
 console.log(file);
  try {
    const response = await axios.post(
      `${url}?resource_type=${resourceType}`,
      formData
    );
   
    console.log("Upload successful:", response.data);
    return response.data;
  } catch (error) {
    console.error("Upload error:", error);
    throw error;
  }
};

export const uploadImageV2 = async (file, onUploadProgress) => {
   const maxSizeInBytes = 10 * 1024 * 1024;
   if (file?.size > maxSizeInBytes) {
     toast.info("File size exceeds 10 MB. Please upload a smaller file.");
     return;
   }

   const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
   const formData = new FormData();
   const preset = `${uploadPreset}`;

   formData.append("file", file);
   formData.append("upload_preset", preset);

   // Add this line to specify the resource type
   const resourceType = file.type.startsWith("image/") ? "image" : "raw";

   try {
     const response = await axios.post(
       `${url}?resource_type=${resourceType}`,
       formData,
       {
         headers: {
           "Content-Type": "multipart/form-data",
         },
         onUploadProgress,
       }
     );
     console.log("Upload successful:", response.data);
     return response.data;
   } catch (error) {
     console.error("Upload error:", error);
     throw error;
   }
 
};

export const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  const pad = (num) => {
    return num < 10 ? "0" + num : num;
  };

  return `${pad(hours)}h :${pad(minutes)}m :${pad(
    remainingSeconds.toFixed(2)
  )}s`;
};
