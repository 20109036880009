import React from "react";

function CloudTable({ data, loading }) {
  console.log(data);
  
  return (
    <div className="mt-3">
      
      <table className="min-w-full  leading-normal">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider ">
              <div className="flex gap-1 items-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.4896 3.97289C15.3264 3.58723 15.1248 3.21544 14.89 2.86765C14.6575 2.52359 14.3902 2.19981 14.0963 1.90536C13.8019 1.61092 13.4781 1.34421 13.134 1.11164C12.7863 0.876937 12.4145 0.674772 12.0288 0.511547C11.2265 0.172294 10.3747 0 9.49667 0C8.61867 0 7.7668 0.172294 6.96454 0.511547C6.57888 0.674772 6.20709 0.876404 5.8593 1.11164C5.51525 1.34421 5.19146 1.61145 4.89702 1.90536C4.60257 2.19981 4.33586 2.52359 4.10329 2.86765C3.86859 3.21544 3.66643 3.58723 3.50373 3.97289C3.16448 4.77515 2.99219 5.62701 2.99219 6.50448C2.99219 7.38249 3.16448 8.23435 3.50373 9.03661C3.66696 9.42227 3.86859 9.79407 4.10329 10.1419C4.33586 10.4864 4.60311 10.8097 4.89702 11.1041C5.19146 11.3986 5.51525 11.6653 5.8593 11.8984C6.20709 12.1331 6.57888 12.3353 6.96454 12.4985C7.7668 12.8377 8.61867 13.01 9.49667 13.01C10.3747 13.01 11.2265 12.8377 12.0288 12.4985C12.4145 12.3353 12.7863 12.1336 13.134 11.8984C13.4781 11.6658 13.8019 11.3986 14.0963 11.1041C14.3908 10.8097 14.6575 10.4859 14.89 10.1419C15.1248 9.79407 15.3269 9.42227 15.4896 9.03661C15.8289 8.23435 16.0012 7.38249 16.0012 6.50448C16.0012 5.62701 15.8289 4.77515 15.4896 3.97289ZM9.49614 11.9437C6.49674 11.9437 4.05689 9.50389 4.05689 6.50448C4.05689 3.50562 6.49674 1.06523 9.49614 1.06523C12.4955 1.06523 14.9354 3.50508 14.9354 6.50448C14.9354 9.50389 12.4955 11.9437 9.49614 11.9437Z"
                    fill="#2F4EFF"
                  />
                  <path
                    d="M3.24024 12.0076L0.156024 15.0918C-0.0520081 15.2998 -0.0520081 15.637 0.156024 15.845C0.260041 15.949 0.396595 16.0007 0.532616 16.0007C0.668638 16.0007 0.805192 15.949 0.909209 15.845L3.99342 12.7608C4.20146 12.5527 4.20146 12.2156 3.99342 12.0076C3.78593 11.7996 3.44827 11.7996 3.24024 12.0076Z"
                    fill="#2F4EFF"
                  />
                  <path
                    d="M6.4782 3.66797C6.18375 3.66797 5.94531 3.90641 5.94531 4.20085V8.86718C5.94531 9.16163 6.18375 9.40007 6.4782 9.40007C6.77264 9.40007 7.01108 9.16163 7.01108 8.86718V4.20085C7.01108 3.90641 6.77211 3.66797 6.4782 3.66797Z"
                    fill="#2F4EFF"
                  />
                  <path
                    d="M8.46648 3.66992C8.17203 3.66992 7.93359 3.90836 7.93359 4.20281V8.86914C7.93359 9.16358 8.17203 9.40202 8.46648 9.40202C8.76092 9.40202 8.99936 9.16358 8.99936 8.86914V4.20281C8.99883 3.90836 8.76039 3.66992 8.46648 3.66992Z"
                    fill="#2F4EFF"
                  />
                  <path
                    d="M12.4509 3.66797C12.1564 3.66797 11.918 3.90641 11.918 4.20085V8.86718C11.918 9.16163 12.1564 9.40007 12.4509 9.40007C12.7453 9.40007 12.9837 9.16163 12.9837 8.86718V4.20085C12.9837 3.90641 12.7453 3.66797 12.4509 3.66797Z"
                    fill="#2F4EFF"
                  />
                  <path
                    d="M10.4587 3.66797C10.1642 3.66797 9.92578 3.90641 9.92578 4.20085V8.86718C9.92578 9.16163 10.1642 9.40007 10.4587 9.40007C10.7531 9.40007 10.9915 9.16163 10.9915 8.86718V4.20085C10.9915 3.90641 10.7531 3.66797 10.4587 3.66797Z"
                    fill="#2F4EFF"
                  />
                </svg>
                Vendor Name
              </div>
            </th>
            <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">
              <div className="flex gap-1 items-center">
                <img src="/cloud1.png" className="h-4 w-5" alt="" />
                Cloud id
              </div>
            </th>
            <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider ">
              <div className="flex gap-1 items-center">
                <svg
                  width="14"
                  height="19"
                  viewBox="0 0 14 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.94865 5.85213C3.46181 5.85213 0.730469 4.78656 0.730469 3.42613C0.730469 2.06569 3.46181 1 6.94865 1C10.4355 1 13.1668 2.06569 13.1668 3.42613C13.1668 4.78656 10.4355 5.85213 6.94865 5.85213ZM6.94865 1.26154C3.71977 1.26154 0.992007 2.25278 0.992007 3.42613C0.992007 4.59935 3.71977 5.59059 6.94865 5.59059C10.1775 5.59059 12.9053 4.59935 12.9053 3.42613C12.9053 2.25278 10.1775 1.26154 6.94865 1.26154Z"
                    fill="#00D67B"
                    stroke="#00D67B"
                    stroke-width="0.6"
                  />
                  <path
                    d="M6.94865 8.89075C3.46181 8.89075 0.730469 7.82519 0.730469 6.46475C0.730469 6.39247 0.788957 6.33398 0.861238 6.33398C0.933519 6.33398 0.992007 6.39247 0.992007 6.46475C0.992007 7.63797 3.71977 8.62921 6.94865 8.62921C10.1775 8.62921 12.9053 7.63797 12.9053 6.46475C12.9053 6.39247 12.9638 6.33398 13.0361 6.33398C13.1083 6.33398 13.1668 6.39247 13.1668 6.46475C13.1668 7.82519 10.4355 8.89075 6.94865 8.89075Z"
                    fill="#00D67B"
                    stroke="#00D67B"
                    stroke-width="0.6"
                  />
                  <path
                    d="M6.94865 11.9279C3.46181 11.9279 0.730469 10.8623 0.730469 9.50186C0.730469 9.42958 0.788957 9.37109 0.861238 9.37109C0.933519 9.37109 0.992007 9.42958 0.992007 9.50186C0.992007 10.6751 3.71977 11.6663 6.94865 11.6663C10.1775 11.6663 12.9053 10.6751 12.9053 9.50186C12.9053 9.42958 12.9638 9.37109 13.0361 9.37109C13.1083 9.37109 13.1668 9.42958 13.1668 9.50186C13.1668 10.8623 10.4355 11.9279 6.94865 11.9279Z"
                    fill="#00D67B"
                    stroke="#00D67B"
                    stroke-width="0.6"
                  />
                  <path
                    d="M6.94865 14.965C3.46181 14.965 0.730469 13.8994 0.730469 12.539C0.730469 12.4667 0.788957 12.4082 0.861238 12.4082C0.933519 12.4082 0.992007 12.4667 0.992007 12.539C0.992007 13.7122 3.71977 14.7034 6.94865 14.7034C10.1775 14.7034 12.9053 13.7122 12.9053 12.539C12.9053 12.4667 12.9638 12.4082 13.0361 12.4082C13.1083 12.4082 13.1668 12.4667 13.1668 12.539C13.1668 13.8994 10.4355 14.965 6.94865 14.965Z"
                    fill="#00D67B"
                    stroke="#00D67B"
                    stroke-width="0.6"
                  />
                  <path
                    d="M6.94865 18.0015C3.46181 18.0015 0.730469 16.936 0.730469 15.5755V3.42764C0.730469 3.35536 0.788957 3.29688 0.861238 3.29688C0.933519 3.29688 0.992007 3.35536 0.992007 3.42764V15.5755C0.992007 16.7487 3.71977 17.74 6.94865 17.74C10.1775 17.74 12.9053 16.7487 12.9053 15.5755V3.42764C12.9053 3.35536 12.9638 3.29688 13.0361 3.29688C13.1083 3.29688 13.1668 3.35536 13.1668 3.42764V15.5755C13.1668 16.936 10.4355 18.0015 6.94865 18.0015Z"
                    fill="#00D67B"
                    stroke="#00D67B"
                    stroke-width="0.6"
                  />
                </svg>
                Storage
              </div>
            </th>
            <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">
              <div className="flex gap-1 items-center">
                <svg
                  width="21"
                  height="18"
                  viewBox="0 0 21 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.4">
                    <path
                      d="M16.7834 1.03846C16.7834 0.463846 16.3195 0 15.7449 0H10.8987H6.74489H1.20643C0.631815 0 0.167969 0.463846 0.167969 1.03846V16.2692C0.167969 16.8438 0.631815 17.3077 1.20643 17.3077H11.3072C11.4526 17.7092 11.8334 18 12.2834 18H19.2064C19.781 18 20.2449 17.5362 20.2449 16.9615V10.0385C20.2449 9.46385 19.781 9 19.2064 9H16.7834V1.03846ZM7.09105 0.692308H10.5526V2.07692H7.09105V0.692308ZM7.09105 2.76923H10.5526V5.04692L10.4487 4.94308C10.3103 4.80462 10.0957 4.80462 9.9572 4.94308L9.51412 5.39308L9.06412 4.94308C9.00182 4.88077 8.91181 4.84615 8.82182 4.84615C8.73182 4.84615 8.64182 4.88077 8.57951 4.95L8.12951 5.39308L7.67951 4.94308C7.54105 4.80462 7.32643 4.80462 7.18797 4.94308L7.08412 5.04692V2.76923H7.09105ZM11.2449 10.0385V16.6154H1.20643C1.01258 16.6154 0.860276 16.4631 0.860276 16.2692V1.03846C0.860276 0.844615 1.01258 0.692308 1.20643 0.692308H6.39874V5.88462C6.39874 6.02308 6.48182 6.14769 6.61335 6.20308C6.74489 6.25846 6.89028 6.22385 6.9872 6.12692L7.4372 5.68385L7.8872 6.13385C8.02566 6.27231 8.24028 6.27231 8.37874 6.13385L8.82182 5.68385L9.27182 6.13385C9.41028 6.27231 9.62489 6.27231 9.76335 6.13385L10.2064 5.68385L10.6564 6.13385C10.7187 6.19615 10.8087 6.23077 10.8987 6.23077C10.9403 6.23077 10.9887 6.22385 11.0303 6.20308C11.1618 6.14769 11.2449 6.02308 11.2449 5.88462V0.692308H15.7449C15.9387 0.692308 16.091 0.844615 16.091 1.03846V9H12.2834C11.7087 9 11.2449 9.46385 11.2449 10.0385ZM19.2064 9.69231C19.4003 9.69231 19.5526 9.84462 19.5526 10.0385V16.9615C19.5526 17.1554 19.4003 17.3077 19.2064 17.3077H12.2834C12.0895 17.3077 11.9372 17.1554 11.9372 16.9615V10.0385C11.9372 9.84462 12.0895 9.69231 12.2834 9.69231H19.2064Z"
                      fill="#FF9900"
                    />
                    <path
                      d="M14.3618 15.2309C14.1679 15.2309 14.0156 15.3832 14.0156 15.5771C14.0156 15.7709 14.1679 15.9232 14.3618 15.9232H15.4002V16.2694C15.4002 16.4632 15.5525 16.6155 15.7464 16.6155C15.9402 16.6155 16.0925 16.4632 16.0925 16.2694V15.9232C16.8541 15.9232 17.4772 15.3002 17.4772 14.5386C17.4772 13.7771 16.8541 13.154 16.0925 13.154V11.7694H17.131C17.3249 11.7694 17.4772 11.6171 17.4772 11.4232C17.4772 11.2294 17.3249 11.0771 17.131 11.0771H16.0925V10.7309C16.0925 10.5371 15.9402 10.3848 15.7464 10.3848C15.5525 10.3848 15.4002 10.5371 15.4002 10.7309V11.0771C14.6387 11.0771 14.0156 11.7002 14.0156 12.4617C14.0156 13.2232 14.6387 13.8463 15.4002 13.8463V15.2309H14.3618ZM16.0925 13.8463C16.4733 13.8463 16.7849 14.1578 16.7849 14.5386C16.7849 14.9194 16.4733 15.2309 16.0925 15.2309V13.8463ZM14.7079 12.4617C14.7079 12.0809 15.0195 11.7694 15.4002 11.7694V13.154C15.0195 13.154 14.7079 12.8425 14.7079 12.4617Z"
                      fill="#FF9900"
                    />
                  </g>
                </svg>
                Price
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="text-sm capitalize">
          {data.length > 0 &&
            data.map((innerData) => {
              return (
                <tr className="border-b capitalize border-b-gray-300">
                  <td>
                    <div className="flex gap-2 items-center px-5 py-3">
                      <img
                        src={
                          innerData?.vendor?.profilePicture
                            ? innerData?.vendor?.profilePicture
                            : "/propic.png"
                        }
                        className="w-10 h-10 rounded-full"
                        alt=""
                      />
                      {innerData?.vendor?.name}
                    </div>
                  </td>
                  <td className=" px-5 py-3 ">{innerData?.cloudId}</td>
                  <td className=" px-7 py-3 ">{innerData?.cloudStorage}</td>
                  <td className=" px-7 py-3 ">
                    {innerData?.currency}{" "}
                    {innerData?.cloudPrice === 0
                      ? "free"
                      : innerData?.cloudPrice?.toFixed()}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {/* {loading && (
        <div className="w-full p-4 flex items-center justify-center">
          <img src="/loading.gif" className="w-32" alt="" />
        </div>
      )} */}
      {data.length < 1 && !loading && (
        <div className="text-red-500 text-center p-4">Oops No Data</div>
      )}
    </div>
  );
}

export default CloudTable;
