export const movieGenre = [
  { name: "Action" },
  { name: "Adventure" },
  { name: "Comedy" },
  { name: "Drama" },
  { name: "Fantasy" },
  { name: "Horror" },
  { name: "Mystery" },
  { name: "Romance" },
  { name: "Science Fiction (Sci-Fi)" },
  { name: "Thriller" },
  { name: "Documentary" },
  { name: "Musical/Dance" },
  { name: "Western" },
  { name: "Animation" },
  { name: "Biographical (Biopic)" },
  { name: "Historical" },
  { name: "War" },
  { name: "Crime" },
];
