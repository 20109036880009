import { Box, Button, Modal, Typography, List, ListItem } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  outline: "none", // Add this line to remove the blue outline
};


export default function NestedModal({ open, handleOpen, locationAccess }) {
  const [error, setError] = useState(null);
  const location = useSelector((state) => state.navbar.location);
  const handleLocationAccess = async () => {
    if (location) {
      handleOpen(false);
      return;
    }
    try {
      await locationAccess();
      handleOpen(false);
    } catch (error) {
      console.error("Location access failed:", error);
      setError(error.message);
    }
  };

  const retryLocationAccess = () => {
    setError(null);
    handleLocationAccess();
  };

  return (
    <Modal open={open}>
      <Box className="text-center rounded-2xl" sx={style}>
        <div className="w-full flex items-center justify-center">
          <div className="flex bg-[#F5F5F5] justify-center items-center w-[120px] h-[120px] rounded-full">
            <svg
              width="63"
              height="85"
              viewBox="0 0 63 85"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.5 0C14.1298 0 0 14.1298 0 31.5C0 37.1585 2.66475 44.2974 8.12585 53.3279C12.6658 60.8287 18.2914 68.2802 23.2426 74.8433C25.529 77.87 27.6838 80.7321 29.3452 83.1008C29.8386 83.8081 30.6446 84.2193 31.5 84.2193C32.3554 84.2193 33.1614 83.8081 33.6548 83.1008C35.3162 80.7321 37.471 77.87 39.7574 74.8433C44.7086 68.2802 50.3178 60.8287 54.8742 53.3279C60.3353 44.2974 63 37.1585 63 31.5C63 14.1298 48.8702 0 31.5 0ZM31.5 46.0574C23.4728 46.0574 16.9426 39.5272 16.9426 31.5C16.9426 23.4728 23.4728 16.9426 31.5 16.9426C39.5272 16.9426 46.0574 23.4728 46.0574 31.5C46.0574 39.5272 39.5272 46.0574 31.5 46.0574Z"
                fill="#FF0000"
              />
            </svg>
          </div>
        </div>
        <Typography variant="h5">Enable Location</Typography>
        {error ? (
          <>
            <Typography>{error}. Please try the following:</Typography>
            <List>
              <ListItem>
                1. Ensure you have an active internet connection
              </ListItem>
              <ListItem>
                2. Allow location access in your browser settings
              </ListItem>
              <ListItem>3. Try refreshing the page</ListItem>
            </List>
            <Button onClick={retryLocationAccess}>Retry Location Access</Button>
          </>
        ) : (
          <>
            <Typography className="text-center">
              This application requires access  your location to function
              properly.
            </Typography>
            <Button
              sx={{
                width: "100%",
                color: "white",
                padding: "9px",
                marginTop: "12px",
                backgroundColor: "#3B2264",
                "&:hover": {
                  backgroundColor: "#2545E0", // Slightly darker shade for hover effect
                },
              }}
              onClick={handleLocationAccess}
            >
              Enable Location
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
}

