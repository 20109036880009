import { debounce, set } from "lodash";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { getBrandData } from "../../../../Api/ApiCall";
import { useDispatch } from "react-redux";
import { loadSpinner } from "../../../../Redux/Features/NavbarSlice";

const SearchBoxData = ({callback, setformData, formData, isBrandAdded,
                        setIsBrandAdded }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [color, setColor] = useState(false);
  const dropdownRef = useRef(null);
  const [brands, setBrands] = useState([])
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [SearchBoxData, setSearchBoxData] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isDrop, setIsdrop] = useState(false)
   const debouncedSearch = useCallback(
     debounce((value) => {
       setSearch(value);
       getBrands();
     }, 400),
     []
   );
  useEffect(() => {getBrands() }, [search,isBrandAdded]);
  const getBrands = () => {
    
    dispatch(loadSpinner())
    getBrandData(search).then((data) => {
      console.log(data?.data?.brands, "this is data");
      setSearchResults(data?.data?.brands);
    
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
        setIsBrandAdded(false);
      dispatch(loadSpinner())
    })
  }
  const handleInputChange = (e) => {
   
    setInputValue(e.target.value); // Update input value immediately
    debouncedSearch(e.target.value); // Debounce the search operation
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
       setIsdrop(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  // console.log(brands, "Brandss");
  const handleBrandSelection = (brandId, brandName) => {
    console.log({brandId });

    setInputValue(brandName);
    setColor(true);
    // setSearchBoxData(brandName);
    if (search) {
      // setSearchResults([]);
    }
    setformData({
      ...formData,
      brand: brandId,
    });
  };

  return (
    <div class="relative w-auto max-w-lg ">
      <>
        <div class="flex justify-between overflow-hidden rounded-md bg-bodycolur shadow shadow-black/20">
          <input
            value={inputValue}
            type="text"
            onChange={handleInputChange}
            onClick={(e) => {
              e.preventDefault();
              setIsdrop(!isDrop);
            }}
            class={`block w-full flex-1 py-3 px-3 focus:outline-none ${
              searchResults?.length > 0
                ? " text-black"
                : "bg-bodycolur text-gray-700"
            }`}
            placeholder="Search Brand..."
          />
          <div class="flex justify-center cursor-pointer items-center  rounded-md bg-navblue px-2 py-2 ">
            <button
              type="button"
              onClick={() => {
                callback();
              }}
              className="w-6 h-6 bg-navblue rounded-xl text-2xl  text-white"
            >
              <p className=" -my-1">+</p>
            </button>
          </div>
        </div>
      </>

      {isDrop && (
        <div
          ref={dropdownRef}
          class={`absolute mt-2 w-full max-h-40 z-30 overflow-x-auto text-white rounded-md bg-navblue`}
        >
          {searchResults.length > 0 ? (
            searchResults?.map((data) => {
              return (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setIsdrop(false);
                    handleBrandSelection(data?._id, data?.name);
                  }}
                  class="flex cursor-pointer py-2 px-3 hover:bg-slate-400"
                >
                  <img
                    src={data?.image}
                    alt=""
                    className="w-8 h-5 mr-2 rounded-lg"
                  />
                  <p class="text-sm font-medium text-white">{data?.name}</p>
                </div>
              );
            })
          ) : (
            <div className="flex p-2 text-xs text-red-300 items-center justify-center">
              No Brands Available
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBoxData;
