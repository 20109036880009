import React, { useEffect, useState } from "react";
import NestedModal from "./LocationPoup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLocation } from "../Redux/Features/NavbarSlice";

function GetCountry() {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  
  const location = useSelector((state)=>state.navbar.location)
  const locationModal = () => {  
    if (location) {
      setModalOpen(false)
      return
    }
    setModalOpen(true);
  };
   const getCountryFromIP = async () => {
     try {
       const response = await axios.get("https://ipapi.co/json/");
       return response.data.country_name;
     } catch (error) {
       console.error("Error fetching country from IP: ", error);
       return "India";
     }
   };
  useEffect(() => {
    locationModal();
  }, []);
  const handlModalOpen = () => {
    setModalOpen(!modalOpen);
  };
  function getUserLocation(maxRetries = 0) {
    return new Promise((resolve, reject) => {
      let retries = maxRetries;

      const attemptGeolocation = () => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              try {
                const { latitude, longitude } = position.coords;
                const geoResponse = await axios.get(
                  `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
                );
                if (geoResponse.data && geoResponse.data.countryName) {
                  resolve(geoResponse.data.countryName);
                  console.log(geoResponse.data.countryName);
                 dispatch(setLocation(geoResponse.data.countryName));
                } else {
                  throw new Error("Country name not found in response");
                }
              } catch (geoError) {
                console.error("Error in geolocation API: ", geoError);
                if (retries > 0) {
                  retries--;
                  setTimeout(attemptGeolocation, 200);
                } else {
                  getCountryFromIP().then((country) => {
                    resolve(country);
                    console.log(country);
                    dispatch(setLocation(country));
                  });
                  reject(
                    new Error(
                      "Failed to determine country from coordinates after multiple attempts"
                    )
                  );
                }
              }
            },
            (error) => {
              console.error("Geolocation error: ", error);
              if (retries > 0) {
                retries--;
                setTimeout(attemptGeolocation, 200);
              } else {
                 getCountryFromIP().then((country) => {
                   resolve(country);
                   console.log(country);
                   dispatch(setLocation(country));
                 });
                  reject(
                    new Error(
                      "User denied location access or geolocation failed"
                    )
                  );
              }
            },
            { enableHighAccuracy: true, timeout: 2000, maximumAge: 0 }
          );
        } else {
          reject(new Error("Geolocation is not supported by this browser"));

        }
      };

      attemptGeolocation();
    });
  }

  return (
    <>
      <NestedModal
        locationAccess={getUserLocation}
        handleOpen={handlModalOpen}
        open={modalOpen}
      />
    </>
  );
}

export default GetCountry;
