import React, { useEffect, useRef, useState } from 'react'
import { getAllCloudUsageHistory } from '../../../../Api/ApiCall';
import { useDispatch } from 'react-redux';
import { loadSpinner } from '../../../../Redux/Features/NavbarSlice';
import { convertToLocalDateTime, formatStorage } from './LocalDateConverter';

function UsageHistory() {
    const loader = useRef(null);
    const dispatch = useDispatch()
    const [usageHistoryData,setUsageHistoryData]=useState([])
    const [totalpages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [remainingStorage, setRemainingStorage] = useState(0);
    useEffect(() => {
      const options = {
        root: null,
        rootMargin: "20px",
        threshold: 0,
      };

      const observerCallback = (entries) => {
        const target = entries[0];
      
        if (target.isIntersecting && 10 < totalpages) {
  
          setPage((prev) => prev + 1);
        }
      };

      const observer = new IntersectionObserver(observerCallback, options);

      const setupObserver = () => {
        if (loader.current) {
          observer.observe(loader.current);
        } else {
          setTimeout(setupObserver, 500);
        }
      };

      setupObserver();

      return () => {
        if (loader.current) {
          observer.unobserve(loader.current);
        }
      };
    }, [loader, totalpages]);

    useEffect(() => {
        getAllUsageHistory()
    },[page])

    const getAllUsageHistory = () => {
        dispatch(loadSpinner());
        getAllCloudUsageHistory(page)
          .then((data) => {
            console.log(data?.data);
            setRemainingStorage(data?.data?.remainingCloud);
            setTotalPages(data?.data?.total);
            setUsageHistoryData((prev) => [...prev, ...data?.data?.cloudUsage]);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(loadSpinner());
          });
    }
  return (
    <>
      <div className="bg-containerWhite p-4 rounded-xl max-h-[650px] overflow-auto">
        <div className="flex justify-between items-center mt-3">
          <h1 className="font-semibold">Usage History</h1>
          <h1 className="text-blue-700">
            Remaining Storage : {formatStorage(remainingStorage)}
          </h1>
        </div>
        <div
          className="overflow-y-auto w-full"
          style={{ scrollbarWidth: "none" }}
        >
          <table className="w-full min-w-[800px] capitalize text-center mt-7">
            <thead className="bg-[#F2F2F2] text-sm h-[40px]">
              <th className="rounded-l-lg">Section</th>
              <th>used data</th>
              <th> Date</th>
              <th className="rounded-r-lg">Time</th>
            </thead>
            <tbody>
              {usageHistoryData?.length > 0 &&
                usageHistoryData?.map((item, index) => {
               const dateAndTime = convertToLocalDateTime(item?.updatedAt);
               return   <tr className="h-[50px] border-b" key={index}>
                    <td>{item?.section}</td>
                    <td>{item?.storageCapacity.toFixed(2)}{item?.storage}</td>
                    <td>{dateAndTime?.date}</td>
                    <td>{dateAndTime?.time}</td>
                  </tr>
                })}
            </tbody>
          </table>
          {!usageHistoryData ||
            (usageHistoryData?.length === 0 && (
              <div className="flex justify-center items-center w-full  h-20">
                <h1 className="text-red-500">No Data Found</h1>
              </div>
            ))}
        </div>
        <div ref={loader} className="h-2 mt-10" />
      </div>
    </>
  );
}

export default UsageHistory