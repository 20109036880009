import React, { useEffect, useRef, useState } from 'react'
import { getAllCloudPurchaseHistory } from '../../../../Api/ApiCall';
import { useDispatch } from 'react-redux';
import { loadSpinner } from '../../../../Redux/Features/NavbarSlice';
import { localDateConverter } from './LocalDateConverter';

function PurchasedHistory() {
    const loader = useRef(null)
    const [totalpages, setTotalPages] = useState(0)
    const [purchaseData, setPurchaseData] = useState([])
    const dispatch = useDispatch()
    const [page,setPage] =useState(1)
    useEffect(() => {
      const options = {
        root: null,
        rootMargin: "20px",
        threshold: 0,
      };

      const observerCallback = (entries) => {
        const target = entries[0];

        if (target.isIntersecting && 10 < totalpages) {
          alert("Loading more data...");
          setPage((prev) => prev + 1);
        }
      };

      const observer = new IntersectionObserver(observerCallback, options);

      const setupObserver = () => {
        if (loader.current) {
          observer.observe(loader.current);
        } else {
          setTimeout(setupObserver, 500);
        }
      };

      setupObserver();

      return () => {
        if (loader.current) {
          observer.unobserve(loader.current);
        }
      };
    }, [loader, totalpages]);
     useEffect(() => {
       getAllPurchaseHistory();
     }, [page]);

    const getAllPurchaseHistory = () => {
        dispatch(loadSpinner())
         getAllCloudPurchaseHistory().then((data) => {
           console.log(data);
            setTotalPages(data?.data?.total);
           setPurchaseData((prev) => [...prev, ...data?.data?.response]);
           })
           .catch((err) => {
             console.log(err);
           })
           .finally(() => {
             dispatch(loadSpinner());
           });
     };
  return (
    <>
      <div className="bg-containerWhite p-4 max-h-[650px] rounded-xl overflow-auto">
        <div className="flex justify-between mt-4 items-center">
          <h1 className="font-semibold">Purchase History</h1>
        </div>
        <div
          className="overflow-y-auto w-full"
          style={{ scrollbarWidth: "none" }}
        >
          <table className="w-full min-w-[800px] text-center capitalize text-center mt-7">
            <thead className="bg-[#F2F2F2] text-sm h-[40px]">
              <th className="rounded-l-lg">plan</th>
              <th>price</th>
              <th>Purchased Date</th>
              <th className="rounded-r-lg">Purchased Data</th>
            </thead>
            <tbody>
              {purchaseData?.length > 0 &&
                purchaseData?.map((item, index) => (
                  <tr key={index} className="h-[50px] border-b">
                    <td>{item?.cloudName}</td>
                    <td>{item?.price.toFixed(2)}</td>
                    <td>{localDateConverter(item?.purchaseDate)}</td>
                    <td>
                      {item?.purchaseData?.storageCapacity}
                      {item?.purchaseData?.storage}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!purchaseData ||
            (purchaseData?.length === 0 && (
              <div className="flex justify-center items-center w-full  h-20">
                <h1 className="text-red-500">No Data Found</h1>
              </div>
            ))}
        </div>
        <div ref={loader} className="h-2 mt-10" />
      </div>
    </>
  );
}

export default PurchasedHistory