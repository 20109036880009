import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { setToastLock } from "../../Redux/Features/NavbarSlice";

export const AppToastContainer = () => {
  const dispatch = useDispatch();
    
  return (
    <ToastContainer
      limit={1}
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      onOpen={() => dispatch(setToastLock(true))}
      onClose={() => dispatch(setToastLock(false))}
    />
  );
};
